



















































































































import axios from "axios";
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { DialogProgrammatic as Dialog } from "buefy";
import { ToastProgrammatic as Toast } from "buefy";
import { isKonsulen, isResiden } from "@/apps/accounts/modules/store";
import APP_CONFIG from "@/apps/core/modules/config";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import KegiatanPendukungFM from "../models/kegiatanPendukungForm";
import KegiatanPendukungVM, { KegiatanPendukung } from "../models/kegiatanPendukung";
import { setRefreshRequest } from "../modules/stores/kegiatan";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";
import useFormModel from "@/apps/core/modules/useFormModel";
import Vue from "vue";

export default defineComponent({
  name: "KegiatanPendukungDetail",
  components: {
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    LulusTag: () => import("@/apps/core/components/LulusTag.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const form = new KegiatanPendukungFM(["verifikasi"], []);
    const composition = useFormModel(form, router.currentRoute.params.id);
    const instance = composition.instance;
    const kegiatan = instance as KegiatanPendukung;
    const fotoDataRef = ref(null);

    const canChange = computed(() => {
      return kegiatan.extraData && kegiatan.extraData.canChange;
    });

    const isUploading = ref(false);
    const uploadPhoto = new UploadPhoto("kegiatan", (uploading: boolean) => {
      isUploading.value = uploading;
    });

    const saveFoto = async (url: string) => {
      const uploadDocUrl = `${APP_CONFIG.baseAPIURL}/aktivitas/kegiatan/dokumen/`;
      const data = { kegiatan: paramsId, berkas: url };
      try {
        const response = await axios.post(uploadDocUrl, data);
        if (kegiatan.dokumenSet === null) kegiatan.dokumenSet = [];
        const length = kegiatan.dokumenSet.length;
        kegiatan.dokumenSet.splice(length, 0, {
          id: response.data.id,
          berkas: response.data.berkas,
        });
        fotoDataRef.value = null;
        Toast.open("Foto berhasil di upload.");
      } catch {
        Toast.open("Foto gagal di upload.");
      }
    };

    const uploadFoto = (foto: File) => {
      uploadPhoto.requestAWS(foto, saveFoto);
    };

    const menuConfigs = [
      {
        label: "Ubah",
        onClick: () =>
          router.push({ name: "kegiatan-pendukung-change", params: { id: paramsId } }),
      },
      {
        label: "Hapus",
        onClick: () => {
          Dialog.confirm({
            message: "Hapus kegiatan ini?",
            onConfirm: async () => {
              const kegiatanVM = new KegiatanPendukungVM();
              if (kegiatan.id === null) return;
              await kegiatanVM.api.delete(kegiatan.id);
              setRefreshRequest("context-menu");
              router.go(-1);
            },
          });
        },
      },
    ];

    const galleryId = ref(`g${new Date().getTime()}`);
    const imageMap = reactive({} as Record<string, boolean>);

    const updateImageMap = (event: Event, imgId: string) => {
      const el = event.target as HTMLInputElement;
      imageMap[imgId] = el.checked;
    };
    const deleteImages = async () => {
      const kegiatan = composition.instance as KegiatanPendukung;
      // api call untuk hapus gambar
      if (!kegiatan.dokumenSet) return;
      const url = `${APP_CONFIG.baseAPIURL}/aktivitas/kegiatan/dokumen-delete/`;
      try {
        const docs = kegiatan.dokumenSet.filter((dok) => {
          return imageMap[dok.id];
        });
        const pkArr = docs.map((doc) => doc.id);
        const resp: Record<string, any> = await axios.delete(url, {
          data: { 'pk_list': pkArr },
        });
        const deletedDocs = resp.data;
        Toast.open("Data berhasil dihapus.");
        kegiatan.dokumenSet = kegiatan.dokumenSet.filter((dok) => {
          return !deletedDocs.includes(dok.id);
        });
        for (const docId of deletedDocs) {
          delete imageMap[docId];
        }
      } catch (error) {
        const errorMessage = "Gagal menghapus data.";
        Toast.open(errorMessage);
        throw new Error(errorMessage);
      }
      for (const docId of Object.keys(imageMap)) {
        imageMap[docId] = false;
      }
      galleryId.value = `g${new Date().getTime()}`;
    };

    watch(composition.instance, () => {
      const kegiatan = instance as KegiatanPendukung;
      if (kegiatan.dokumenSet) {
        for (const dok of kegiatan.dokumenSet) {
          Vue.set(imageMap, dok.id, false);
        }
      }
    });


    const verifikasiKegiatan = async () => {
      const apiUrl = `${APP_CONFIG.baseAPIURL}/aktivitas/kegiatan-pendukung/${paramsId}/`;
      const data = { verifikasi: kegiatan.verifikasi };
      try {
        await axios.patch(apiUrl, data);
        Toast.open("Data berhasil di simpan.");
        setRefreshRequest("context-menu");
      } catch {
        Toast.open("Data gagal di simpan.");
      }
    };

    return {
      // Data
      galleryId,
      imageMap,
      isKonsulen,
      isResiden,
      menuConfigs,
      kegiatan,
      fotoDataRef,
      isUploading,

      // Computed
      canChange,

      // Composition
      ...composition,

      // Method
      deleteImages,
      uploadFoto,
      toUserDateFormat,
      updateImageMap,
      verifikasiKegiatan
    };
  },
});
