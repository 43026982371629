


































































































import { defineComponent } from "@vue/composition-api";
import { has } from "lodash";
import KegiatanPendukungFM from "../models/kegiatanPendukungForm";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "KegiatanPendukungForm",
  props: { headerText: String },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isCreate = !has(router.currentRoute.params, "id");
    const requiredFields = [
        "tanggal", "jenis", "konsulen", "lokasi"
    ]
    const form = new KegiatanPendukungFM(requiredFields, ["catatan"]);
    const paramsId = router.currentRoute.params.id;
    const composition = useFormModel(form, paramsId);
    const kegiatan = composition.instance;

    return {
      // Data
      kegiatan,
      isCreate,

      // Composition
      ...composition,
    };
  },
});
