import FormModel from "@/apps/core/models/formModel";
import { KegiatanPendukung, KegiatanPendukungAPI } from "./kegiatanPendukung";
import { setRefreshRequest } from "../modules/stores/kegiatan";

export default class KegiatanPendukungFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new KegiatanPendukungAPI(),
      new KegiatanPendukung(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }
}
